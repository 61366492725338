#modal-wrapper {
    border-radius: var(--inputBorderRadius);
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    opacity: 1;
}

#modal-wrapper[class*="error"] {
    background: #ff770063;
}

#modal-wrapper[class*="success"] {
    background: #19875463;
}

#modal-wrapper[class*="error"] svg {
    fill: #FF7900;
}

#modal-wrapper[class*="success"] svg {
    fill: #198754;
}

#modal-wrapper[class*="open"] {
    animation: modal-bounce-open .7s linear forwards;
}

#modal-wrapper[class*="close"] {
    animation: modal-bounce-close .7s linear forwards;
}

.modal-message {
    font-size: 20px;
    color: #fff;
    text-align: start !important;
}

#modal-wrapper svg {
    width: 35px;
}

@keyframes modal-bounce-open {
    0% {
        top: -100%;
    }

    70% {
        top: 20%;
    }

    100% {
        top: 2%;
    }
}

@keyframes modal-bounce-close {
    0% {
        top: 2%;
    }

    70% {
        top: 20%;
    }

    100% {
        top: -100%;
    }
}